import React, { Component } from 'react';
import Spinner from './Spinner';
import NewsItem from './NewsItem';

export default class News extends Component {
  static defaultProps = {
    pageSize: 6,
    category: 'general',
  };

  constructor(props) {
    super(props);
    this.state = {
      articles: [],
      totalResults: 0,
      loading: false,
      page: 1,
    };
  }

  async componentDidMount() {
    this.fetchArticles(this.props.category);
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.category !== this.props.category) {
      this.fetchArticles(this.props.category);
    }
  }

  async fetchArticles(category) {
    this.setState({ loading: true });
    const url = `/.netlify/functions/news?category=${category}`;
    try {
      const res = await fetch(url);
      const data = await res.json();
      this.setState({
        articles: data.articles,
        totalResults: data.articles.length,
        loading: false,
      });
    } catch (error) {
      console.error('Error fetching data:', error); // Log any errors during fetch
      this.setState({ loading: false });
    }
  }

  handlePrevClick = () => {
    this.setState((prevState) => ({
      page: Math.max(prevState.page - 1, 1),
    }));
  };

  handleNextClick = () => {
    this.setState((prevState) => ({
      page: Math.min(
        prevState.page + 1,
        Math.ceil(this.state.totalResults / this.props.pageSize)
      ),
    }));
  };

  render() {
    const { articles, loading, page } = this.state;
    const startIndex = (page - 1) * this.props.pageSize;
    const selectedArticles = articles.slice(
      startIndex,
      startIndex + this.props.pageSize
    );

    return (
      <div className="container my-3">
        <h1 className="text-center">Trending News</h1>
        <div className="d-flex justify-content-center mb-3"></div>
        {loading && <Spinner />}
        <div className="row">
          {!loading &&
            selectedArticles.map((element) => (
              <div className="col-md-4" key={element.url}>
                <NewsItem
                  publishedAt={element.publishedAt}
                  title={element.title}
                  summarized={element.summarized}
                  imageUrl={
                    element.urlToImage
                      ? element.urlToImage
                      : require('../images/imageurl.png')
                  }
                  newsUrl={element.url}
                />
              </div>
            ))}
        </div>
        <div className="d-flex justify-content-between">
          <button
            disabled={page <= 1}
            type="button"
            className="btn btn-dark"
            onClick={this.handlePrevClick}
          >
            &larr; Previous
          </button>
          <button
            disabled={
              page >= Math.ceil(this.state.totalResults / this.props.pageSize)
            }
            type="button"
            className="btn btn-dark"
            onClick={this.handleNextClick}
          >
            Next &rarr;
          </button>
        </div>
      </div>
    );
  }
}
