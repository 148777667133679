import React, { Component } from "react";
import Navbar from "./components/Navbar";
import News from "./components/News";
import About from "./components/About";
import Footer from "./components/Footer";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

export default class App extends Component {
  render() {
    return (
      <div className="d-flex flex-column min-vh-100">
        <Router>
          <Navbar />
          <div className="flex-grow-1">
            <Routes>
              <Route
                exact
                path="/"
                element={<News key="general" pageSize={6} category="general" />}
              />
              <Route
                path="/general"
                element={<News key="general" pageSize={6} category="general" />}
              />
              <Route
                path="/technology"
                element={
                  <News key="technology" pageSize={6} category="technology" />
                }
              />
              <Route
                path="/business"
                element={
                  <News key="business" pageSize={6} category="business" />
                }
              />
              <Route
                path="/entertainment"
                element={
                  <News
                    key="entertainment"
                    pageSize={6}
                    category="entertainment"
                  />
                }
              />
              <Route
                path="/health"
                element={<News key="health" pageSize={6} category="health" />}
              />
              <Route
                path="/science"
                element={<News key="science" pageSize={6} category="science" />}
              />
              <Route
                path="/sports"
                element={<News key="sports" pageSize={6} category="sports" />}
              />
              <Route path="/about" element={<About />} />
            </Routes>
          </div>
          <Footer />
        </Router>
      </div>
    );
  }
}
