import React from "react";
import "./about.css";

const About = () => {
  return (
    <div className="container my-5">
      <h1>About This Website</h1>
      <p>
        Welcome to our news website! This platform uses advanced AI technology,
        specifically a Large Language Model (LLM), to provide you with concise
        and accurate summaries of the latest news articles. Our goal is to help
        you stay informed with minimal effort by presenting the key points of
        each story in an easily digestible format.
      </p>
      <p>
        <strong>Key Features:</strong>
      </p>
      <ul>
        <li>Summarized news articles for quick reading.</li>
        <li>Accurate and relevant titles generated by AI.</li>
        <li>
          Ability to read the full article by clicking on the "Read more" link.
        </li>
        <li>
          Daily updates to ensure you have the latest news at your fingertips.
        </li>
      </ul>
      <p>
        This website is developed by <strong>Akash Vishwakarma</strong>. The
        integration of the LLM model ensures that you get the most relevant and
        up-to-date information in the shortest possible time. We hope you find
        this service useful and efficient.
      </p>
      <p>
        If you have any questions or feedback, please feel free to reach out at
        <a href="mailto:akash.vishwakarma.5477@gmail.com">
          {" "}
          akash.vishwakarma.5477@gmail.com
        </a>
        .
      </p>
    </div>
  );
};

export default About;
